import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"
import ArticuloIIPage from "../../components/articulos/articulo_ii";
import ArticleDetail from "../../components/investment-ideas/ArticleDetail";


const InvestmentDynamicRoute = ({ props, data }) => {
  const art = data.investmentArticles;
  const artId = art.artid;
  const artSlug = art.slug;
  const artTitle = art.title;
  const artMetaTitle = art.metatitle;
  const artDesc = art.description;
  const artImgUrl = art.imgUrl;
  const htmlContent = art.htmlcontent;
  const timestamp = art.timestamp;
  const artDate = art.date;

  const [showDynamicContent, setShowDynamicContent] = useState(false);

  useEffect(() => {
    setShowDynamicContent(true);
  }, []);

  return (
      showDynamicContent
          ? <ArticleDetail slug={artSlug}/>
          : <ArticuloIIPage
              artid={artId}
              slug={artSlug}
              title={artTitle}
              metatitle={artMetaTitle}
              description={artDesc}
              imgurl={artImgUrl}
              htmlcontent={htmlContent}
              timestamp={timestamp}
              artDate={artDate}
          />
  )
}

export const query = graphql`
  query ($id: String) {
      investmentArticles(id: { eq: $id}) {
        artid
        slug
        title
        metatitle
        description
        htmlcontent
        section
        imgUrl
        date
        timestamp
      }
    }
`

export default InvestmentDynamicRoute
